import { graphql } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import styled from 'styled-components'
import CTA from '../components/CTA'
import GatsbyHead from '../components/GatsbyHead'
import LayoutWrapper from '../components/LayoutWrapper'
import LightBackground from '../components/LightBackground'
import Wrapper from '../components/Wrapper'
import useSignUpLink from '../hooks/queries/useSignUpLink'
import { useIntl } from '../utils/IntlContext'
import media, { legacyBreakpoints } from '../utils/media'

export const query = graphql`
  query CVTemplate($contentful_id: String!, $locale: String!) {
    contentfulCvTemplate(contentful_id: { eq: $contentful_id }, node_locale: { eq: $locale }) {
      title_tag
      page_title
      used_count
      live_pdf
      description {
        description
      }
      template_image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 560)
          }
        }
      }
      template_image_alt
      template_image_title
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  min-width: 560px;
  border-radius: var(--border-radius);
  border: 1px solid var(--color-light-outline);
  margin: unset;
  overflow: initial;

  @media screen and (max-width: ${legacyBreakpoints.tablet}) {
    min-width: 380px;
  }

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    min-width: initial;
    margin: 0 30px;
    overflow: hidden;
  }

  img {
    width: 100%;
  }
`

const CvBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 130px 30px 50px 30px;
  flex-direction: row-reverse;

  @media screen and (max-width: ${legacyBreakpoints.mobile}) {
    flex-direction: column;
    padding: 130px 0 50px;
  }
`

const Content = styled.div`
  min-height: calc(100vh - 130px);
  margin-bottom: 50px;
  padding: 0 30px 20px;
  background-color: var(--color-background-light);

  ${media.sm`
    min-height: initial;
    margin-bottom: initial;
    padding: 0 0 0 95px;
    background-color: unset;
  `}
`

const Stat = styled.div`
  padding: 0;
  margin-bottom: 40px;

  h3 {
    display: inline;
    margin-left: 10px;
  }
`

const ViewTemplateCTA = styled(CTA)`
  margin-right: 20px;
  margin-bottom: 20px;
`

const TemplatePage = ({
  pageContext: { translatedPages },
  data: {
    contentfulCvTemplate: {
      page_title,
      used_count,
      live_pdf,
      description: { description },
      template_image,
      template_image_alt,
      template_image_title,
    },
  },
}) => {
  const { locale, t } = useIntl()

  return (
    <LayoutWrapper translatedPages={translatedPages}>
      <Wrapper>
        <LightBackground
          $mobileHeight="100vh"
          $height={600}
        />
        <CvBox>
          <Content>
            <h1>{page_title}</h1>
            <p>{description}</p>
            {used_count && (
              <Stat>
                {t('templates_used')}: <h3>{used_count.toLocaleString(locale, { minimumFractionDigits: 0 })}</h3>
              </Stat>
            )}
            <ViewTemplateCTA
              to={live_pdf}
              $secondary
              rel="nofollow">
              {t('view_template')}
            </ViewTemplateCTA>
            <CTA
              to={useSignUpLink(locale)}
              $primary>
              {t('get_template')}
            </CTA>
          </Content>
          <StyledGatsbyImage
            image={getImage(template_image.localFile)}
            alt={template_image_alt}
            title={template_image_title}
          />
        </CvBox>
      </Wrapper>
    </LayoutWrapper>
  )
}

/** @type {import('gatsby').HeadFC} */
export const Head = ({
  pageContext: { canonicalUrl, noIndex, translatedPages },
  data: {
    contentfulCvTemplate: {
      title_tag,
      description: { description },
      template_image,
    },
    site: {
      siteMetadata: { siteUrl },
    },
  },
}) => {
  const metaImage = getSrc(template_image.localFile)

  return (
    <GatsbyHead
      pageTitle={title_tag}
      metaDescription={description}
      noIndex={noIndex}
      ldJson={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': canonicalUrl,
        },
        headline: title_tag,
        image: {
          '@type': 'ImageObject',
          url: `${siteUrl}${metaImage}`,
        },
        publisher: {
          '@type': 'Organization',
          name: 'VisualCV',
          logo: {
            '@type': 'ImageObject',
            url: `${siteUrl}/assets/images/vcv_blue.png`,
          },
        },
        description,
        isAccessibleForFree: false,
      }}
      canonicalUrl={canonicalUrl}
      translatedPages={translatedPages}
      image={metaImage}
    />
  )
}

export default TemplatePage
